import React, { FunctionComponent, useContext } from "react";
import { TestEventContext } from "../../../Contexts/TestEventContext";
import useSendLockout from "../../../CustomHooks/useSendLockout";
import useVisibility from "../../../CustomHooks/useVisibility";
import { Lockout } from "../../../Interfaces/Lockout";
import { TokenContext } from "../../../Providers/TokenProvider";
import NetworkError from "../../utility/error/network/NetworkError";
import LockoutMessage from "./LockoutMessage";

type LockoutControllerProps = {
  initialLockout: Lockout;
};

const LockoutController: FunctionComponent<LockoutControllerProps> = ({
  children,
  initialLockout
}) => {
  const { returnUrl } = useContext(TokenContext);
  const TestEvent = useContext(TestEventContext);
  const { lockout, isLoading, sendLockout, error } = useSendLockout({
    initialLockout
  });
  const { isHidden, refocus } = useVisibility({
    active: TestEvent.useLockout,
    hideOnInit: initialLockout.lockout > 0,
    onTrigger: sendLockout
  });

  if (error) {
    return <NetworkError />;
  }

  if ((isHidden && lockout.isLockout) || initialLockout.isLockout) {
    const lockoutLocation = new URL(returnUrl);
    window.location.replace(lockoutLocation.origin + lockoutLocation.pathname);
    return <LockoutMessage lockout={lockout} refocus={refocus} />;
  }

  return isHidden ? (
    <LockoutMessage lockout={lockout} refocus={refocus} isLoading={isLoading} />
  ) : (
    children
  );
};

export default LockoutController;
